import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { FaUser, FaEnvelope, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import Select from "react-select";
import Sidebar from "../../components/Sidebar";
import { Title, LoadingTitle } from "../../components/Title";
import { DeleteUser } from "./DeleteUser";
import {
  URL,
  token,
  LoadingIcon,
  formSelectStyles,
  validateEmail,
  SuccessMessage,
  LoadingTable,
} from "../../components/Utils";

import "../../stylesheets/UserInfo.scss";
import { loadingAnimation } from "../../components/scripts/functions";

export const NewUser = () => {
  const { id } = useParams();
  const creatingUser = id === "new-user" ? true : false;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [accountsLoading, setAccountsLoading] = useState(false);
  const [accountsError, setAccountsError] = useState("");
  const [linkedAccounts, setLinkedAccounts] = useState([]);
  const [client, setClient] = useState({
    name: "",
    email: "",
    password: "",
    role: "5",
  });

  const navigate = useNavigate();

  const getUser = async () => {
    setLoading(true);
    try {
      const resp = await fetch(`${URL}/user/subusers/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      const data = await resp.json();
      if (resp.ok) {
        setClient((prevClient) => ({
          ...prevClient,
          name: data.subuser.name,
          email: data.subuser.email,
          role: data.subuser.role.toString(),
        }));

        // Set linked accounts from the response
        const linkedAccountIds = data.subuser.linked_users.map((user) => user.id);
        setLinkedAccounts(linkedAccountIds);
      } else {
        console.error("Failed to fetch user details.");
      }
    } catch (error) {
      console.error("Error fetching user:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAccounts = async () => {
    setAccountsLoading(true);
    setAccountsError("");
    try {
      const resp = await fetch(`${URL}/user/accounts`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      const data = await resp.json();
      if (resp.ok) {
        setAccounts(data.accounts);

        // If creating a new user, toggle all accounts by default
        if (creatingUser) {
          setLinkedAccounts(data.accounts.map((account) => account.id));
        }
      } else {
        setAccountsError(data.message || "Failed to fetch accounts");
      }
    } catch (error) {
      console.error("Error fetching accounts:", error);
      setAccountsError("An unexpected error occurred while fetching accounts");
    } finally {
      setAccountsLoading(false);
    }
  };

  const [error, setError] = useState();
  const showError = (message) => {
    setError(message);
    setTimeout(() => {
      setError("");
    }, 5000);
  };

  const handleUserUpload = async (e) => {
    e.preventDefault();

    // Ensure a valid role is set
    if (!client.role || !["4", "5"].includes(client.role)) {
      showError("Please select a valid role for the subuser.");
      return;
    }

    // Prepare the client data
    let curClient = {
      ...client,
      linkedAccounts, // Include the linked accounts in the payload
      company_id: id, // Include the company ID from the route parameter
    };

    setLoading(true);

    if (creatingUser) {
      loadingAnimation(true);
      const resp = await fetch(`${URL}/user/subusers`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token()}`,
        },
        body: JSON.stringify(curClient), // Send the updated client object
      });

      setLoading(false);
      loadingAnimation(false);

      const data = await resp.json();
      if (resp.ok) {
        if (data.message === "Subuser created") {
          navigate("/users");
        } else {
          showError(data.message || "There was an error adding the user.");
        }
      } else {
        showError("There was an error adding the user.");
      }
    } else {
      loadingAnimation(true);
      const resp = await fetch(`${URL}/user/subusers/${id}`, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token()}`,
        },
        body: JSON.stringify(curClient), // Send the updated client object
      });

      setLoading(false);
      loadingAnimation(false);

      const data = await resp.json();
      if (resp.ok) {
        if (data.message === "Update successful") {
          navigate("/users");
        } else {
          showError(data.message || "There was an error updating the user.");
        }
      } else {
        showError("There was an error updating the user.");
      }
    }
  };

  const handleToggleAccount = (accountId) => {
    setLinkedAccounts((prev) =>
      prev.includes(accountId)
        ? prev.filter((id) => id !== accountId)
        : [...prev, accountId]
    );
  };

  useEffect(() => {
    if (!creatingUser) {
      getUser();
    }
    fetchAccounts();
  }, []);


  return (
    <>
      {!creatingUser && !client && !loading ? (
        <>
          <LoadingTitle />
          <div id="create-user-page" className="page-content">
            <LoadingTable />
          </div>
        </>
      ) : (
        <>
          <div className="top">
            <Title
              title={`Users`}
              links={[
                { link: "/", title: "Home" },
                { link: "/users", title: "Users" },
                creatingUser
                  ? { link: `/users/new-user`, title: `New User` }
                  : { link: `/users/${id}`, title: `${client.name}` },
              ]}
            />
          </div>
          <div id="create-user" className="page-content">
            <div className="body">
              <section className="_1 user-info mobile-column">
                <div className="left">
                  <p className="_1 f-16 l-black bold">User Information</p>
                </div>
              </section>

              <section className="_3 email-container">
                <div className="container mobile-column">
                  <div className="left">
                    <p className="_1 f-16 l-black regular">Full Name</p>
                  </div>
                  <div className="right input flex ai-c">
                    <div className="icon">
                      <FaUser />
                    </div>
                    <input
                      type="text"
                      placeholder="Full Name"
                      value={client.name}
                      onChange={(e) => {
                        let aux = { ...client };
                        aux.name = e.target.value;
                        setClient(aux);
                      }}
                      autoComplete="off"
                    />

                  </div>
                </div>
              </section>
              <section className="_2 email-container">
                <div className="container mobile-column">
                  <div className="left">
                    <p className="_1 f-16 l-black regular">Username</p>
                  </div>
                  <div className="right input flex ai-c">
                    <div className="icon">
                      <FaEnvelope />
                    </div>
                    <input
                      type="email"
                      placeholder="Email Address"
                      value={client.email}
                      onChange={(e) => {
                        let aux = { ...client };
                        aux.email = e.target.value;
                        setClient(aux);
                      }}
                      autoComplete="off"
                    />

                  </div>
                </div>
              </section>
              <section className="_4 password-container">
                <div className="container mobile-column">
                  <div className="left">
                    <p className="_1 f-16 l-black regular">Password</p>
                  </div>
                  <div className="right input flex ai-c">
                    <div className="icon">
                      <FaLock />
                    </div>
                    <input
                      type={viewPassword ? "text" : "password"}
                      placeholder="Password"
                      value={client.password}
                      onChange={(e) => {
                        let aux = { ...client };
                        aux.password = e.target.value;
                        setClient(aux);
                      }}
                      autoComplete="new-password"
                    />

                    <div
                      className="icon view-password"
                      onClick={() => setViewPassword(!viewPassword)}
                    >
                      {viewPassword ? <FaEyeSlash /> : <FaEye />}
                    </div>
                  </div>
                </div>
              </section>
              <section className="_6 account-container">
                <div className="container mobile-column">
                  <div className="left">
                    <p className="_1 f-16 l-black regular">Linked Accounts</p>
                    <p className="_2 f-14 light-text regular">
                      Select which accounts this subuser can view.
                    </p>
                  </div>
                  <div className="right input flex-column">
                    {(accounts || []).map((account) => (
                      <div key={account.id} className="flex ai-c toggle-row">
                        <p className="f-14 l-black regular">
                          {account.account_no}
                        </p>
                        <label className="toggle-switch">
                          <input
                            type="checkbox"
                            checked={linkedAccounts.includes(account.id)}
                            onChange={() => handleToggleAccount(account.id)}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
              <section className="_5 email-container">
                <div className="container mobile-column">
                  <div className="left">
                    <p className="_1 f-16 l-black regular">Position</p>
                  </div>
                  <Select
                    styles={customSelectStyles}
                    defaultValue={"4"}
                    onChange={(e) => {
                      let aux = { ...client };
                      aux.role = e.value;
                      setClient(aux);
                    }}
                    options={[
                      { label: "Manager", value: "4" },
                      { label: "User", value: "5" },
                    ]}
                    className="custom-select"
                    placeholder={client.role === "4" ? "Manager" : "User"}
                  />
                </div>
              </section>
              <div className="buttons flex ai-c">
                <a
                  href=""
                  className="button f-12 white regular green-bg"
                  onClick={handleUserUpload}
                >
                  {loading ? <LoadingIcon /> : "Save"}
                </a>
                {!creatingUser && <DeleteUser id={id} from={"single"} />}
              </div>
            </div>
            {error && <p className="error-msg-item">{error}</p>}
          </div>
        </>
      )}
    </>
  );
};

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "white",
    padding: "3px 12px",
    minHeight: "none",
    width: "100%",
    minWidth: "none",
    border: "1px solid var(--border-color)",
  }),
  input: (provided) => ({
    ...provided,
    color: "black",
    fontSize: "12px",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "black",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    color: "black",
    maxHeight: "100%",
    svg: {
      color: "black",
      width: "16px!important",
    },
  }),
};
