import "../../stylesheets/Login.scss";
import userLogo from "../../assets/loginAssets/user-logo.svg";
import LoadingIcon from "../LoadingIcon";
import passwordLogo from "../../assets/loginAssets/password-logo.svg";

import "../../stylesheets/Login.scss";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Cookies from "universal-cookie";

async function loginUser(credentials) {
  const URL = process.env.REACT_APP_API_URL;
  return fetch(URL + "/user/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    Accept: "application/json",
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}
export const Login = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [wrongStyle, setWrongStyle] = useState([]);
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const setErrorStyle = () => {
    setWrongStyle({
      border: "1px solid red",
    });
  };

  let emptyUser = false;
  let emptyPass = false;
  typeof userName === "undefined" || userName?.length <= 0
    ? (emptyUser = true)
    : (emptyUser = false);
  typeof password === "undefined" || password?.length <= 0
    ? (emptyPass = true)
    : (emptyPass = false);

  const validate = () => {
    if (emptyUser) {
      setErrorMsg("Username cannot be empty");
      setErrorStyle();
    }
    if (emptyPass) {
      setErrorMsg("Password cannot be empty");
      setErrorStyle();
    }
    if (emptyPass && emptyUser) {
      setErrorMsg("Username and Password cannot be empty");
      setErrorStyle();
    }
  };

  //Where the form's data arrives and gets validated
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (emptyPass || emptyUser) {
      validate();
      setValid(true);
      setLoading(false);
    } else {
      const cookies = new Cookies();
      const token = await loginUser({
        userName,
        password,
      });

      //Checks if user & password entered are valid
      if (token.hasOwnProperty("token")) {
        cookies.set("token_user", token?.token, {
          maxAge: 86400, //to expire after 1 day of being created - >86400
        });
        cookies.set("name_user", token?.name);
        cookies.set("role_user", token?.role);
        cookies.set("primary", token?.company?.company_primary_contact);
        setLoading(false);
        navigate("/");
      } else {
        setErrorMsg("The username and password did not match");
        setLoading(false);
        setErrorStyle();
        setValid(false);
      }
    }
  };
  return (
    <>
      <div id="login">
        <img
          src={require("../../assets/atlantic-main-logo.png")}
          alt=""
          id="main-logo"
        />
        <p className="login-title">ATLANTIC FREIGHT BROKERS</p>
        {/**ERROR MESSAGE */ <p id="wrong-msg">{errorMsg}</p>}
        <form className="inputs" onSubmit={handleSubmit}>
          <div
            style={
              typeof userName === "undefined" || userName?.length <= 0 || !valid
                ? wrongStyle
                : {}
            }
            className="input-container"
          >
            <img src={userLogo} alt="" />
            <input
              type="text"
              placeholder="Username"
              onChange={(e) => setUserName(e?.target?.value)}
            />
          </div>
          <div
            style={
              typeof password === "undefined" || password?.length <= 0 || !valid
                ? wrongStyle
                : {}
            }
            className="input-container"
          >
            <img src={passwordLogo} alt="" />
            <input
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e?.target?.value)}
            />
          </div>
          {loading ? (
            <button className="button">
              <LoadingIcon />
            </button>
          ) : (
            <input type="submit" value="Login" className="button" />
          )}
        </form>
        <a href="/forgot-password" className="forgot-pass">
          Forgot password
        </a>
      </div>
    </>
  );
};
