import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { BsSearch } from 'react-icons/bs';
import { BiEditAlt } from 'react-icons/bi';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import Select from "react-select";

import Sidebar from '../../components/Sidebar';
import { Title } from '../../components/Title';
import { URL, token, customSelectStyles, LoadingTable } from '../../components/Utils';
import { DeleteUser } from './DeleteUser';

import '../../stylesheets/Admins.scss';
import '../../stylesheets/Users.scss';

export const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalUsers, setTotalUsers] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  const [finalUrl, setFinalUrl] = useState(`${URL}/user/subusers`);
  const [extraUrl, setExtraUrl] = useState({
    page,
    search: "",
    filter: "",
    order_by: "",
    field: "",
  });

  const [noRecords, setNoRecords] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    users && setSearchResults(users);
  }, [users]);

  const gotoPreviousPage = () => {
    if (page > 1) {
      const aux = { ...extraUrl, page: page - 1 };
      setPage(page - 1);
      setExtraUrl(aux);
    }
  };

  const gotoNextPage = () => {
    if (page < totalPages) {
      const aux = { ...extraUrl, page: page + 1 };
      setPage(page + 1);
      setExtraUrl(aux);
    }
  };

  const handleClientFilter = (e) => {
    if (e.value !== "0") {
      const filteredUsers = users.filter(item =>
        item.role === parseInt(e.value, 10)
      );
      setSearchResults(filteredUsers);
    } else {
      setSearchResults(users);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    const filteredUsers = users.filter(item =>
      (item.name || "Unnamed User").toLowerCase().includes(value)
    );

    setSearchResults(filteredUsers);
  };

  const handleOrderBy = (ord) => {
    const orders = ord.split(',');
    const aux = { ...extraUrl, order_by: orders[0], field: orders[1] };
    setExtraUrl(aux);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams();
    Object.keys(extraUrl).forEach(key => {
      if (extraUrl[key]) {
        searchParams.append(key, extraUrl[key]);
      }
    });

    setFinalUrl(`${URL}/user/subusers?${decodeURIComponent(searchParams.toString())}`);
  }, [extraUrl]);

  const getUsers = async () => {
    setLoading(true);
    try {
      const resp = await fetch(finalUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      const data = await resp.json();
      console.log(data);
      if (resp.status === 200) {
        if (!data.company || !data.company.subusers) {
          setNoRecords(true);
        } else {
          setNoRecords(false);
          setUsers(
            data.company.subusers.filter(user => user.active).map(user => ({
              ...user,
              linked_accounts: user.linked_accounts ? user.linked_accounts.split(", ") : [],
            }))
          );
          setLoading(false); 
        }
      } else {
        console.error('Error fetching users');
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getUsers();
  }, [finalUrl]);

  return (
    <>
      <div className="top">
        <Title
          title="Users"
          links={[
            { link: '/', title: 'Home' },
            { link: '/users', title: 'Users' },
          ]}
        />
      </div>
      <div id="home" className="page-content">
        <div id="users">
          <div className="data-table">
            <div className="header flex" style={{ marginBottom: "12px" }}>
              <div className="search-input">
                <input
                  type="text"
                  placeholder="Search users..."
                  onChange={handleSearch}
                />
                <div className="icon">
                  <BsSearch />
                </div>
              </div>
              <div className="filters flex">
                <Select
                  styles={customSelectStyles}
                  defaultValue={"all_customers"}
                  onChange={handleClientFilter}
                  options={[
                    { label: "All Users", value: "0" },
                    { label: "Primary", value: "3" },
                    { label: "User", value: "5" },
                    { label: "Managers", value: "4" },
                  ]}
                  className="custom-select"
                  placeholder="All Users"
                />
                <NavLink to="/users/new-user">
                  <button id='new-user-button'>
                    Add User
                  </button>
                </NavLink>
              </div>
            </div>
            {noRecords ? (
              <h4>No users found</h4>
            ) : (
              <UsersTable
                loading={loading}
                users={searchResults}
                orderBy={handleOrderBy}
                sortData={[extraUrl.order_by, extraUrl.field]}
                removeUser={getUsers}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const UsersTable = ({ users, orderBy, sortData, removeUser, loading }) => {
  const handleRole = (role) => {
    const Circle = ({ color }) => (
      <span
        className="circle"
        style={{
          display: "block",
          width: "7.5px",
          height: "7.5px",
          borderRadius: "50%",
          backgroundColor: color,
        }}
      />
    );

    let formRole = "";
    let color = "red";
    switch (role) {
      case 3:
        formRole = "Primary";
        color = "red";
        break;
      case 5:
        formRole = "User";
        color = "var(--green)";
        break;
      case 4:
        formRole = "Manager";
        color = "#197FB7";
        break;
      default:
        formRole = "Not defined";
        color = "red";
        break;
    }
    return (
      <>
        <Circle color={color} />
        {formRole}
      </>
    );
  };

  return (
    <>
      {loading ? (
        <LoadingTable />
      ) : (
        <div className="table">
          <div className="table-header">
            <div className="left">
              <div className="table-head username" onClick={() => orderBy(sortData.join(",") === "1,0" ? "0,0" : "1,0")}>
                <p>Username</p>
                <span className="arrow">
                  {sortData.join(",") === "1,0" ? <FaAngleUp /> : <FaAngleDown />}
                </span>
              </div>
              <div className="table-head role" onClick={() => orderBy(sortData.join(",") === "1,1" ? "0,1" : "1,1")}>
                <p>Position</p>
                <span className="arrow">
                  {sortData.join(",") === "1,1" ? <FaAngleUp /> : <FaAngleDown />}
                </span>
              </div>
              <div className="table-head accounts">
                <p>Accounts</p>
              </div>
            </div>
            <div className="right">
              <div className="table-head user-actions">
                <p>Actions</p>
              </div>
            </div>
          </div>
          {Array.isArray(users) && users.length > 0 ? (
            users.map(user => (
              <div className="table-body" key={user.id}>
                <div className="left">
                  <div className="table-bod username">
                    {user.role === 3 ? (
                      <p>{user.account_no || "Unnamed User"}</p>
                    ) : (
                      <NavLink to={`/users/${user.id}`}>
                        <p>{user.name || "Unnamed User"}</p>
                      </NavLink>
                    )}
                  </div>
                  <div className="table-bod role">
                    <p className="table-role">{handleRole(user.role)}</p>
                  </div>
                  <div className="table-bod accounts">
                    <p>
                      {user.role === 4 || user.role === 5
                        ? user.linked_accounts?.join(", ") || "N/A"
                        : "Not Applicable"}
                    </p>
                  </div>
                </div>
                <div className="right">
                  <div className="table-bod user-actions">
                    <div className="actions">
                      {user.role !== 3 && (
                        <>
                          <NavLink to={`/users/${user.id}`}>
                            <BiEditAlt />
                          </NavLink>
                          <DeleteUser id={user.id} from="table" removeUser={removeUser} />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="no-records">
              <p>No users available</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};
